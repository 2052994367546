import React, { useState } from "react"
import { Box } from "rebass"
import MobileDownloadCTA from "@components/MobileDownloadCTA"
import DesktopDownloadForm from "@components/mailchimpFormDesktopDownload.js"
import { StaticImage } from "gatsby-plugin-image"

const Hero = props => {
  const [isVideo, setVideo] = useState(false)

  return (
    <React.Fragment>
      <h1
        style={{
          color: "white",
          fontWeight: 600,
          fontSize: "2.5rem",
          textAlign: "center",
          marginTop: "62px",
        }}
      >
        NoSQL Workbench vs Dynobase
      </h1>
      <p
        style={{
          color: "white",
          fontWeight: 400,
          fontSize: "1rem",
          textAlign: "center",
        }}
      >
        NoSQL Workbench is a perfect tool for DynamoDB data modeling and is
        super useful in the pre-development stage. Dynobase shines in DynamoDB
        data administration. Multiple tabs, bookmarks, variety of shortcuts,
        improved UX, and codegen make administrative tasks much easier.
      </p>
      {!isVideo ? (
        <StaticImage
          src="../../../images/hero.png"
          style={{
            borderRadius: 5,
          }}
        />
      ) : (
        <div
          style={{
            overflow: "hidden",
            paddingTop: "56.25%",
            position: "relative",
            marginBottom: "4.9em",
          }}
        >
          {" "}
          <iframe
            style={{
              border: "0",
              height: "100%",
              left: "0",
              position: "absolute",
              top: "0",
              width: "100%",
            }}
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/SGHBz6qe_YE"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="dynamodb gui client"
            allowFullScreen
            autoPlay="1"
          ></iframe>
        </div>
      )}
      {!isVideo && (
        <p
          style={{
            textAlign: "center",
            color: "rgba(0,0,0,0.7)",
            fontSize: "0.7em",
            cursor: "pointer",
            marginTop: "0.8em",
            marginBottom: "2.5em",
          }}
          onClick={() => setVideo(true)}
        >
          See Dynobase in action - Click to Play Video
        </p>
      )}
      <MobileDownloadCTA />
      <Box display={["none", "none", "block"]} mb={3}>
        <DesktopDownloadForm data={props.data} />
      </Box>
      <p
        style={{
          textAlign: "center",
          color: "rgba(0,0,0,0.7)",
          fontSize: "0.7em",
          lineHeight: 1.3,
        }}
      >
        7-day free trial. Available for macOS, Linux and Windows
      </p>
    </React.Fragment>
  )
}

export default Hero
