import React from "react"
import Section from "@components/section"
import { Box } from "rebass"

const UnleashThePower = ({ data }) => (
  <React.Fragment>
    <div
      style={{
        textAlign: "center",
        marginTop: "122px",
        fontSize: "2rem",
        fontWeight: "600",
        lineHeight: "1.3",
        letterSpacing: -1,
      }}
      id="features"
    >
      10 Things You Can Do With Dynobase (That Can't Be Done In NoSQL Workbench)
    </div>
    <Section
      title="Editing Data"
      desc="In NoSQL Workbench, it is not easy to just change an attribute of an item, add a new one or just delete it. Dynobase makes database edits as easy as editing a spreadsheet."
      imageName="edit.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Multi-tab support for multi-taskers"
      desc="Need to cross reference some data or copy it between multiple tables in different regions and profiles? Just open a new tab, like in a web browser."
      imageName="tabs.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Intuitive User Experience"
      desc="Just two clicks away from seeing your data. Dynobase's primary goal is to make data exploration and management easy. With autocomplete, advanced filtering capabilities, streaming and faster query engine, you're well equipped."
      imageName="betterux.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Native SSO Support"
      desc="Dynobase works out of the box with SSO-based profiles, temporary credentials, 3rd party identity providers like aws-vault and so on."
      imageName="sso.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Terminal"
      desc="Thanks to Terminal, you can blur the line between UI and Code. Slice and dice your data inside Dynobase to get results filtered and transformed beyond normal capabilities using Javascript."
      imageName="terminal.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="History of Operations"
      desc="In Dynobase, you can see the history of actions just like in your web browser."
      imageName="history.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Import & Export"
      desc="Import and export your data from JSON or CSV files to DynamoDB and the other way around without writing complex scripts. Migrate to DynamoDB in a few clicks. There's export to S3 too!"
      imageName="import.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Don't like forms? Write raw queries"
      desc="We know that some queries are just too complicated to be presented using UI. And sometimes it's just more convenient to write code. We got this."
      imageName="raw.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Box mt={62}>
      <p style={{ textAlign: "center" }}>
        These are just 10 highlighted features,{" "}
        <a
          href="https://dynobase.dev/#features"
          target="_blank"
          rel="noreferrer"
        >
          there's a whole lot more
        </a>
        .
      </p>
    </Box>
  </React.Fragment>
)

export default UnleashThePower
