import React from "react"
import { FooterWithCTA as Footer, Container, SEO, Layout } from "@components"
import TenThings from "@components/pages/nosql-workbench-alternative/TenThings"
import Testimonials from "@components/pages/nosql-workbench-alternative/Testimonials"
import Hero from "@components/pages/nosql-workbench-alternative/Hero"
import { graphql } from "gatsby"
import Board from "../components/tweets/Board"

const IndexPage = ({ data }) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          backgroundColor: "rgb(24, 144, 255)",
          overflow: "hidden",
          width: "100%",
          height: "850px",
          msTransform: "skewY(-6deg)",
          transform: "skewY(-6deg)",
          WebkitTransform: "skewY(-6deg)",
          top: -300,
          zIndex: -1000,
        }}
      >
        {" "}
      </div>
      <div
        style={{
          position: "absolute",
          backgroundColor: "rgb(24, 144, 255, 0.3)",
          overflow: "hidden",
          width: "100%",
          height: "860px",
          msTransform: "skewY(-6deg)",
          transform: "skewY(-6.6deg)",
          WebkitTransform: "skewY(-6.2deg)",
          top: -280,
          zIndex: -1000,
        }}
      >
        {" "}
      </div>
      <Layout>
        <SEO
          title="#1 NoSQL Workbench Alternative"
          description="Ready for a NoSQL Workbench alternative? Learn how Dynobase is an ideal choice for DynamoDB data administration and production workflow."
          canonical="https://dynobase.dev/nosql-workbench-alternative/"
        />
        <Container>
          <Hero data={data} />
          <TenThings data={data} />
          <Testimonials data={data} />
          <h2 style={{ textAlign: "center" }}>Endorsed by AWS community</h2>
          <Board />
        </Container>
        <Footer />
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allImageSharp {
      nodes {
        gatsbyImageData(width: 800)
        fluid {
          originalName
        }
      }
    }
  }
`

export default IndexPage
