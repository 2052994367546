import React from "react"
import { Box, Flex, Image } from "rebass"
import { GatsbyImage } from "gatsby-plugin-image"

const Testimonial = ({
  author,
  authorSub,
  authorImage,
  children,
  imageName,
  allImageSharpNodes,
}) => {
  const image = allImageSharpNodes.find(n =>
    n.fluid.originalName.includes(imageName)
  )

  return (
    <>
      <Box
        sx={{
          boxShadow: "15px 15px 45px #d9d1d7, -15px -15px 45px #ffffff",
          position: "relative",
          minWidth: "75%",
          borderRadius: "10px",
          padding: "25px",
          paddingTop: "22px",
          paddingBottom: "35px",
          marginBottom: "160px",
        }}
        minWidth={["70%", "80%", "90%"]}
      >
        {children}
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          style={{
            position: "absolute",
            bottom: -110,
            left: 0,
            right: 0,
          }}
          width="100%"
        >
          {image ? (
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={`${author} avatar`}
              loading="lazy"
              style={{
                borderRadius: "100%",
                boxShadow: "15px 15px 45px #d9d1d7",
                width: "64px",
                height: "64px",
                zIndex: 0,
              }}
            />
          ) : (
            <Image
              alt={`${author} avatar`}
              src={authorImage}
              width="64"
              height="64px"
              sx={{
                borderRadius: "100%",
                boxShadow: "15px 15px 45px #d9d1d7",
              }}
            ></Image>
          )}
          <h5 style={{ marginTop: "5px", zIndex: 10000 }}>{author}</h5>
          <h6
            style={{ marginTop: "-20px", color: "#666", textAlign: "center" }}
          >
            {authorSub}
          </h6>
        </Flex>
      </Box>
    </>
  )
}

export default Testimonial
