import React, { useEffect, useState } from "react"
import Button from "./button"
import { Flex, Box } from "rebass"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    maxWidth: "900px",
    borderRadius: "6px",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
}

export default ({ style, primary }) => {
  const [isModalVisible, setModalVisible] = useState(false)

  const [downloadLink, setDownloadLink] = useState(
    "https://dynobase.dev/download"
  )

  useEffect(() => {
    if (
      navigator.userAgent.indexOf("Mac") !== -1 ||
      navigator.userAgent.indexOf("Win") !== -1 ||
      navigator.userAgent.indexOf("mac") !== -1 ||
      navigator.userAgent.indexOf("win") !== -1
    ) {
      setDownloadLink("https://dynobase.dev/download")
    } else {
      setDownloadLink("https://github.com/dynobase/dynobase/releases")
    }
  })

  const showModal = () => {
    setModalVisible(true)
    setTimeout(function() {
      window.location = "https://dynobase.dev/download"
    }, 500)
  }

  return (
    <Flex
      id="mailchimp-desktop-start"
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        href={downloadLink}
        linkClassName="dynobase-download-button"
        buttonClassName="dynobase-download-button-btn"
        primary={primary}
        style={{
          paddingLeft: 40,
          cursor: "pointer",
          paddingRight: 40,
          ...style,
        }}
      >
        Download Now
      </Button>
      <a
        style={{
          color: "white",
          textDecoration: "underline",
          fontSize: "14px",
          cursor: "pointer",
          marginTop: "8px",
        }}
        href="https://dl.todesktop.com/220811zswf4aj4x/builds/230820feca027nv"
      >
        Try Dynobase 2.5.0 Beta
      </a>
    </Flex>
  )
}
